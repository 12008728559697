import styled from "styled-components";

export const InfoContainer = styled.div`
  padding: 20px;
  height:  fit-content;
  background-color: #010101;
  border-radius: 10px;
  width: 420px;
  overflow: hidden;
  position: absolute;
  top:70%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;

  @media screen and (max-width: 400px) {
    width: 85%;
  }
`;

export const FormWrap = styled.div`
  margin: 0 auto;
  color: #010606;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Form = styled.form`
  text-align: left;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.8rem;
`;

export const Heading = styled.h1`
  font-size: 0.8em;
  margin-top: 5px;
  text-align: center;
  padding-bottom: 8px;
  z-index: 0;
  color: yellow;
  border-bottom: 1px solid grey
`;
export const InfoP1 = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 0.8em;
  text-align: center;
  color: #fff;
`;

export const InfoP2 = styled.p`
  font-size: 0.8em;
  color: yellow;
  text-align: center;
`;

export const Label = styled.label`
  position: relative;
  top: 10px;
  color: yellow;
`;
export const SmallText = styled.small`
  font-weight: light;
  color: yellow;
`;
export const Input = styled.input`
  display: block;
  text-align: left;
  padding: 5px;
  width: 100%;
  border: 1px solid grey;
  border-radius: 6px;
  outline: none;
`;
export const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid grey;
  border-radius: 6px;
  outline: none;
`;

export const ButtonEl = styled.button`
  background: #010101;
  color: #fff;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border-radius: 6px;
  border: none;


  &:hover {
    background: #ffd300;
    color: #010101;
  }
`;
